import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const ContactIntro = () => {
  const ContactIntro = styled.div`
    grid-column-end: span 11;
    text-align: center;

    @media (min-width: 46rem) {
      grid-column-end: span 4;
      text-align: left;
    }

    h5 {
      font-size: 1.9rem;
      font-family: "IBMPlexSans", "sans-serif";
      font-weight: 500;

      @media (max-width: 46rem) {
        margin-bottom: 0.6rem;
      }
    }

    p {
      font-size: 0.9rem;

      @media (max-width: 46rem) {
        margin: 0;
      }

      a {
        font-weight: bold;
        color: inherit;
        text-decoration: none;

        &:hover {
          color: ${props => props.theme.primaryBlue};
        }
      }
    }
  `

  return (
    <ContactIntro>
      <h5>Get in touch</h5>
      <p>
        Would you like to work together, or simply chat about a project? Don't
        hesitate to{" "}
        <Link to={`/contact`} title="Get in touch!">
          contact me
        </Link>
        .
      </p>
    </ContactIntro>
  )
}

export default ContactIntro
