import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import styled from "@emotion/styled"
import Social from "./social"

const ContactList = () => {
  const data = useStaticQuery(graphql`
    query ContactInfo {
      craft {
        globalSets(handle: "contact") {
          ... on Craft_contact_GlobalSet {
            contactEmail
            contactPhone
          }
        }
      }
    }
  `)

  const contactEmail = data.craft.globalSets[0].contactEmail
  const contactPhone = data.craft.globalSets[0].contactPhone
  const contactPhoneLink = contactPhone.replace(/ /g, "").replace("07", "+447")

  const ContactList = styled.div`
    grid-column-end: span 11;
    text-align: center;

    @media (min-width: 46rem) {
      grid-column-end: span 2;
      text-align: left;
    }

    h5 {
      font-size: 1.3rem;
      font-family: "IBMPlexSans", "sans-serif";
      font-weight: 500;

      @media (max-width: 46rem) {
        margin-bottom: 0.6rem;
      }
    }
  `

  const ContactMethods = styled.ul`
    margin: 0 0 1rem;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      font-size: 0.9rem;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  `

  return (
    <ContactList>
      <h5>Contact</h5>
      <ContactMethods>
        <li>
          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </li>
        <li>
          <a href={`tel:${contactPhoneLink}`}>{contactPhone}</a>
        </li>
      </ContactMethods>

      <Social color="black" location="footer" />
    </ContactList>
  )
}

export default ContactList
