import React from "react"
import styled from "@emotion/styled"
import { ThemeProvider } from "emotion-theming"
import GlobalStyles from "../utils/global"
import Header from "./structure/header"
import Footer from "./structure/footer"
import Cursor from "./fragments/cursor"

const theme = {
  primaryYellow: "rgb(243,231,0)",
  primaryBlue: "rgb(0,3,237)",
  textColor: "rgb(30, 32, 35)",
  grays: {
    xxlight: "rgb(240,240,240)",
    xlight: "rgb(220,220,220)",
    light: "rgb(150,150,150)",
    mid: "rgb(118,118,118)",
    dark: "rgb(50,50,50)",
    xdark: "rgb(10,10,10)",
  },
  default: {
    black: "rgb(0,0,0)",
    white: "rgb(255,255,255)",
  },
}

const MainContainer = styled.main`
  width: 100%;
  margin-top: -4.53rem;

  @media (min-width: 46rem) {
    margin-top: -5.88rem;
  }
`

export default class Layout extends React.Component {
  render() {
    const props = this.props

    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header />
        <MainContainer>{props.children}</MainContainer>
        <Footer />
        <Cursor />
      </ThemeProvider>
    )
  }
}
