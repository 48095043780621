import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const LogoStyle = styled.div`
  display: block;
  line-height: 0;

  @media (min-width: 46rem) {
    grid-column-end: span 1;
  }

  svg {
    transition: fill 0.33s ease-in-out;
    fill: ${props => props.theme.textColor};
    height: 1.75rem;

    &:hover {
      transition: fill 0.33s ease-in-out;
      fill: ${props => props.theme.primaryBlue};
    }
  }

  a {
    font-weight: 400;
    font-size: 120%;
    color: ${props => props.theme.textColor};
    transition: color 0.22s ease-in-out;
    text-decoration: none;
    /* text-transform: uppercase; */

    &:hover {
      color: ${props => props.theme.primaryBlue};
      transition: color 0.22s ease-in-out;
    }
  }
`

const Logo = () => {
  return (
    <LogoStyle>
      <Link to="/" title="David Perkins">
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.99 57.58">
          <path d="M143.82,45.09a6.18,6.18,0,0,0,1.12-4.43l-1.06-7.5a7.2,7.2,0,0,0-2.34-4.31l-.29-.24a6.51,6.51,0,0,0,0-1.65l-1-7.41a6.91,6.91,0,0,0-.38-1.47l.18-.22a5.88,5.88,0,0,0,1.12-4.43l-1-7.45a7.18,7.18,0,0,0-2.35-4.31A6.47,6.47,0,0,0,133.29,0L111.62.76a5.25,5.25,0,0,0-3,1A6.21,6.21,0,0,0,105.42,1L97,1.27a5.16,5.16,0,0,0-3.2,1.23L94,2.28,91.84,3.9a6.51,6.51,0,0,0-5-2.27L77.66,2a5.23,5.23,0,0,0-3.06,1.1L74.7,3h0l-1.09.81,0,0a10.39,10.39,0,0,0-5.69-1.46L51,2.88a10.07,10.07,0,0,0-3.61.84A10.68,10.68,0,0,0,46,4.55l-4.33,3.2a14.46,14.46,0,0,0-2.77-2.18,13.22,13.22,0,0,0-7.34-2l-17.84.62a5.29,5.29,0,0,0-3.07,1.11l.11-.09-8.6,6.35a5.08,5.08,0,0,0-1,.94A5.83,5.83,0,0,0,.06,16.92L4.92,51.61a7.13,7.13,0,0,0,2.35,4.3,6.43,6.43,0,0,0,4.43,1.67L29.54,57a12,12,0,0,0,2.34-.35,11,11,0,0,0,2.55-.94,10.34,10.34,0,0,0,1-.6c.14-.1.29-.17.42-.27h0L38.31,53l.66-.49a7.08,7.08,0,0,0,1.72,2.28,6.44,6.44,0,0,0,4.43,1.66l8.55-.3a5.22,5.22,0,0,0,3-1l.66-.49a6.35,6.35,0,0,0,3.9,1.24l8.55-.3a5.39,5.39,0,0,0,1.57-.29,5.1,5.1,0,0,0,1.39-.72l6-4.44.28.65A6.8,6.8,0,0,0,85.28,55l8.25-.29a5.26,5.26,0,0,0,3-1L103,48.9a7.21,7.21,0,0,0,2.18,3.6,6.52,6.52,0,0,0,4.43,1.66l21.68-.76a5.29,5.29,0,0,0,3-1l8.6-6.35A8.22,8.22,0,0,0,143.82,45.09ZM93.54,2.7c-.11.1-.2.2-.3.31C93.34,2.9,93.43,2.8,93.54,2.7Zm-19.07.46a5.21,5.21,0,0,0-.71.7,5.11,5.11,0,0,1,.45-.48A2.75,2.75,0,0,1,74.47,3.16Zm59.68,3,1,7.46L122,14.1l.85,6.06,11.41-.4,1,7.4-11.4.4.87,6.26L138,33.36l1,7.51-21.67.76L112.48,6.94Zm-27.86,1-5,35L93,42.48,78.52,8.13l9.14-.32,7.71,21.88L97.83,7.45ZM68.74,8.47a4.3,4.3,0,0,1,4.45,3.93L77.48,43l-8.55.3-1.21-8.63L60.2,35l1.21,8.63-8.55.3L48.57,13.26c-.34-2.42,1-4.12,3.31-4.2ZM66.67,27.18,65.21,16.75a1.22,1.22,0,0,0-1.33-1l-5.3.19A.91.91,0,0,0,57.69,17l1.46,10.43,7.52-.27M32.42,9.74a7.91,7.91,0,0,1,7.71,6.8L43,37.1c.55,3.95-2.1,7.2-5.73,7.32l-17.84.63L14.58,10.36Zm1.69,24.73-2.06-14.7a2.67,2.67,0,0,0-2.77-2.43l-5.1.18,2.76,19.72,5.11-.18a2.09,2.09,0,0,0,2.06-2.59" />
        </svg> */}
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146 74">
          <defs />
          <path
            fill-rule="nonzero"
            d="M108 0l38 74H71l37-74zM23 73H1V1h22l13 2c4 1 7 4 10 6 3 3 6 7 8 12s3 10 3 16-1 11-3 16-5 9-8 11a34 34 0 01-23 9zm-9-60v48h5c6 0 12-2 16-5 5-3 8-10 8-19s-3-16-8-19c-4-3-10-5-16-5h-5z"
          />
        </svg> */}
        <svg viewBox="0 0 399 135" xmlns="http://www.w3.org/2000/svg">
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="Group" fill="#000000">
              <path
                d="M45.8544721,33.0849752 C45.2753996,23.739943 41.7011244,21.1241327 34.412798,21.1241327 L23.01106,21.1241327 C20.4351857,35.141681 25.5270302,49.1592293 31.3776594,63.1568097 L42.7793974,63.1568097 C49.9479157,63.1568097 53.4622868,60.5409994 50.0876918,51.1959671 C47.971082,45.1456578 46.3736406,39.1352845 45.8544721,33.0849752 M67.340059,29.7103802 C67.4998031,37.9971075 69.8560292,46.2838348 72.9510719,54.5505941 C81.0381191,74.3588675 71.2338224,82.2062984 51.2658049,82.2062984 L39.1052822,82.2062984 C45.1955275,99.0792733 47.7114977,115.93228 34.412798,132.825223 L12.747499,132.825223 C47.0924892,89.2550086 -24.0535574,45.6648263 10.3114008,2.0746439 L44.0972865,2.0746439 C64.8041208,2.0746439 67.6395793,9.9220748 67.340059,29.7103802"
                id="Fill-4"
              ></path>
              <path
                d="M106.752932,62.0226263 L118.134702,62.0226263 C125.323188,62.0226263 128.797624,59.406816 125.522869,50.0617837 C123.625907,44.4108348 122.168242,38.7399178 121.689009,33.0889688 C121.109937,23.7439366 117.535662,21.1281263 110.247335,21.1281263 L98.8455971,21.1281263 C96.3296269,34.7662823 101.101983,48.3844703 106.752932,62.0226263 Z M137.703359,132.829217 C145.830343,115.457041 137.224127,98.084866 125.223348,80.7126907 L114.400683,80.7126907 C120.850353,98.084866 123.925427,115.457041 110.247335,132.829217 L88.5620681,132.829217 C122.927026,89.2390342 51.7809797,45.6688199 86.1259699,2.0786375 L119.931824,2.0786375 C140.61869,2.0786375 143.454148,9.92606841 143.174596,29.7143738 C143.314372,37.5019007 145.411014,45.2894275 148.226505,53.0769544 C152.978893,65.5769334 151.461323,73.4243643 143.913413,77.3580638 C157.431761,95.82848 168.074714,114.318864 159.728083,132.829217 L137.703359,132.829217 Z"
                id="Fill-6"
              ></path>
              <path
                d="M217.435654,132.82722 C226.381325,113.897539 215.418884,94.9678585 201.8606,76.0381778 C201.181687,82.3880074 200.183286,88.737837 198.246389,95.0876666 C200.842231,107.667518 199.704054,120.247369 189.799917,132.82722 L168.11465,132.82722 C202.479608,89.2370374 131.333562,45.6668231 165.678552,2.0766407 L187.343851,2.0766407 C171.908573,21.705202 177.819107,41.2938272 185.84625,60.9024205 C186.025962,41.2938272 188.342252,21.705202 212.024321,2.0766407 L234.787861,2.0766407 C213.561858,18.4504151 207.192061,34.8241895 204.536314,51.1979639 C223.066634,78.3944039 251.840548,105.610812 239.480345,132.82722 L217.435654,132.82722 Z"
                id="Fill-8"
              ></path>
              <path
                d="M243.759491,132.82722 C278.124449,89.2370374 206.978403,45.6668231 241.323393,2.0766407 L262.988692,2.0766407 C250.888073,29.0334644 279.162786,55.9902882 297.852851,82.9471119 C288.308138,55.9902882 269.198746,29.0334644 290.444716,2.0766407 L310.25299,2.0766407 C275.888031,45.6668231 347.034078,89.2370374 312.689088,132.82722 L292.141998,132.82722 C304.941497,104.492603 273.192349,76.1579859 254.781837,47.843337 C263.647637,76.1579859 285.892008,104.492603 263.567765,132.82722 L243.759491,132.82722 Z"
                id="Fill-10"
              ></path>
              <path
                d="M358.927029,48.7638626 C356.930228,42.6736172 355.552434,36.5833719 355.352754,30.4731585 C355.212978,22.2463353 351.878319,19.2511326 345.388713,19.2511326 C339.198628,19.2511326 333.188255,22.2463353 333.308063,30.4731585 C333.427871,33.4483931 333.787295,36.4435958 334.386336,39.4387984 C335.844001,46.1480523 339.258532,49.1632229 346.62673,52.6975621 C352.756912,55.5529886 359.006901,58.4084151 365.276859,61.2838096 C380.552392,68.3724559 391.195346,74.163181 395.768022,88.1807293 C397.645015,94.4706548 398.843096,100.76058 398.70332,107.050506 C397.365463,127.218204 374.302403,134.30685 355.532466,134.30685 L355.173042,134.30685 C334.286496,134.30685 332.309662,127.397916 333.707423,107.050506 C333.867167,99.4426912 332.090014,91.8548445 329.494171,84.2470298 L350.800046,84.2470298 C353.056432,90.8564437 354.69381,97.4458895 354.973362,104.075271 C355.19301,112.102414 358.487733,115.656721 365.137083,115.656721 C371.546816,115.656721 377.617094,112.102414 377.377478,104.075271 C377.237701,100.640772 376.738501,97.2062732 375.959748,93.7917422 C374.342339,87.0625203 370.967744,83.7078934 363.140281,80.1535862 C357.0101,77.2981597 350.820014,74.4227652 344.570025,71.5673387 C328.994971,64.2590442 318.471826,58.308575 314.358414,44.670419 C312.760973,38.9395979 311.842444,33.2087769 312.002188,27.4979239 C313.340045,8.06904277 337.980579,0.581036182 354.99333,0.581036182 L355.212978,0.581036182 C374.222531,0.581036182 377.95655,8.04907476 376.638661,27.4979239 C376.419013,34.5865701 377.916614,41.6752164 380.212936,48.7638626 L358.927029,48.7638626 Z"
                id="Fill-12"
              ></path>
            </g>
          </g>
        </svg>
        {/* David Perkins */}
      </Link>
    </LogoStyle>
  )
}

export default Logo
