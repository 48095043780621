import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

const ListeningContainer = styled.div`
  grid-column-end: span 11;
  text-align: center;

  @media (min-width: 46rem) {
    grid-column-end: span 4;
    text-align: left;
  }

  p {
    margin: 1rem 0 0 0;

    @media (min-width: 46rem) {
      margin: 0;
    }

    &:before {
      content: "🎧";
      display: inline-block;
      margin-right: 0.55rem;
    }
  }
`

const Listening = () => {
  const data = useStaticQuery(graphql`
    query {
      allSpotifyRecentTrack(sort: { fields: order }) {
        edges {
          node {
            order
            played_at
            track {
              name
              artists {
                name
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ListeningContainer>
      <p>
        {data.allSpotifyRecentTrack.edges[0].node.track.artists[0].name} -{" "}
        {data.allSpotifyRecentTrack.edges[0].node.track.name}
      </p>
    </ListeningContainer>
  )
}

export default Listening
