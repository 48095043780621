import React from "react"
import { animateScroll as scroll } from "react-scroll"
import styled from "@emotion/styled"

const JumpContainer = styled.div`
  @media (max-width: 46rem) {
    display: none;
  }

  @media (min-width: 46rem) {
    grid-column-end: span 2;
    text-align: left;
  }
`

const TopLink = styled.button`
  margin-bottom: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  color: ${props => props.theme.textColor};

  &:hover {
    color: ${props => props.theme.primaryBlue};
  }
`

export default class ToTop extends React.Component {
  scrollToTop = () => {
    scroll.scrollToTop()
  }

  render() {
    return (
      <JumpContainer>
        <TopLink onClick={this.scrollToTop} smooth={true} duration={500}>
          Top ↑
        </TopLink>
      </JumpContainer>
    )
  }
}
