import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Social from "../../site/social"
import DarkModeToggle from "../../fragments/dark"
import styled from "@emotion/styled"

const MobileNavStyle = styled.nav`
  display: inline-flex;
  line-height: 0;
  position: relative;
  top: -0.2rem;

  @media (min-width: 46rem) {
    display: none;
  }

  ul {
    width: 100%;
    text-align: left;
    margin: 1.5rem 0;
    padding: 0;
    line-height: 3.5rem;
  }

  hr {
    background-color: ${props => props.theme.primaryBlue};
    height: 3px;
    width: 30%;
    margin: 2rem 0 2rem 1rem;
  }
`

const Hamburger = styled.button`
  z-index: 2;
  display: flex;
`

const MobileMenu = styled.div`
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0rem 1rem;
  background: ${props => props.theme.default.white};
  box-shadow: 0 8px 16px -1px rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100%;
  z-index: 1;

  &.active {
    transition: opacity 0.18s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.2s cubic-bezier(0.86, 0, 0.07, 1);
    transform: translateX(0);
    opacity: 1;

    ul {
      li {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          transform: translateX(0);
          transition-property: all;
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-duration: 0.44s;
          opacity: 1;
        }

        &:nth-of-type(1) {
          transition-delay: 0.11s;
        }

        &:nth-of-type(2) {
          transition-delay: 0.22s;
        }

        &:nth-of-type(3) {
          transition-delay: 0.33s;
        }

        &:nth-of-type(4) {
          transition-delay: 0.44s;
        }
      }
    }
  }

  &.hidden {
    transition: opacity 0.18s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.2s cubic-bezier(0.86, 0, 0.07, 1);
    transform: translateX(-100vw);
    opacity: 0;

    ul {
      li {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          transform: translateX(-15rem);
          will-change: opacity, transform;
          opacity: 0;
        }
      }
    }
  }
`

const MenuItem = styled.li`
  display: flex;
  margin: 0 1rem;
  padding-left: 0;

  a {
    color: ${props => props.theme.textColor} !important;
    font-family: "BluuNext", sans-serif;
    font-size: 2.6rem;
    line-height: 3.5rem;
    text-decoration: none;
  }
`

const MobileNav = () => {
  const data = useStaticQuery(graphql`
    query MobileMenuLinks {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
        }
      }
      craft {
        globalSets(handle: "social") {
          ... on Craft_social_GlobalSet {
            socialLinks {
              ... on Craft_socialLinks_network_BlockType {
                networkName
                networkUrl
              }
            }
          }
        }
      }
    }
  `)

  const mobileMenuLinks = data.site.siteMetadata.menuLinks
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <MobileNavStyle className="mobile-navigation">
      <Hamburger
        onClick={() => toggleExpansion(!isExpanded)}
        className={`hamburger ${isExpanded ? `is-active` : `is-not-active`}`}
        type="button"
        aria-label="Open Menu"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </Hamburger>

      <MobileMenu className={`mobmenu ${isExpanded ? `active` : `hidden`}`}>
        <ul>
          {mobileMenuLinks.map(link => (
            <MenuItem key={link.name}>
              <Link to={link.link} title={link.name}>
                {link.name}
              </Link>
            </MenuItem>
          ))}
        </ul>
        <hr />
        <Social color="black" location="nav" />
        <hr />
        <DarkModeToggle />
      </MobileMenu>
    </MobileNavStyle>
  )
}

export default MobileNav
