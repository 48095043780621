import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({
  title,
  description,
  image,
  pathname,
  article,
  website,
  blog,
  homepage,
  created,
  updated,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername,
          defaultDateCreated,
          defaultDateUpdated,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${image || defaultImage}`,
        url: `${siteUrl}/${pathname || "/"}`,
        created: created || defaultDateCreated,
        updated: updated || defaultDateUpdated,
      }

      const schemaWebsite = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "David Perkins",
        alternateName: "Digital Designer and Front-end developer",
        url: siteUrl,
        logo: {
          "@type": "ImageObject",
          url: "https://prkns.me/LOGO.png",
          width: 200,
          height: 60,
        },
        sameAs: [
          "https://twitter.com/perki/",
          "https://instagram.com/prkns/",
          "https://www.linkedin.com/in/prkns/",
          "https://github.com/prkns/",
          "https://prkns.me/",
        ],
      }

      const schemaArticle = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": seo.url,
        },
        headline: seo.title,
        description: seo.description,
        image: seo.image,
        author: {
          "@type": "Person",
          name: "David Perkins",
        },
        publisher: {
          "@type": "Organization",
          name: "David Perkins",
          logo: {
            "@type": "ImageObject",
            url: "https://prkns.me/LOGO.png",
            width: 200,
            height: 60,
          },
        },
        datePublished: seo.created,
        dateModified: seo.updated,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {article && <meta property="og:type" content="article" />}
            {article && (
              <script type="application/ld+json">
                {JSON.stringify(schemaArticle)}
              </script>
            )}
            {website && <meta property="og:type" content="website" />}
            {website && (
              <script type="application/ld+json">
                {JSON.stringify(schemaWebsite)}
              </script>
            )}
            {blog && <meta property="og:type" content="blog" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <html lang="en" />
            {homepage && <link rel="canonical" href={`${siteUrl}/`} />}
            {!homepage && (
              <link rel="canonical" href={`${siteUrl}/${pathname}/`} />
            )}
            <link
              rel="preload"
              href={"/fonts/BluuNext-Bold.woff2"}
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            ></link>
            <link
              rel="preload"
              href={"/fonts/BluuNext-Bold.woff"}
              as="font"
              type="font/woff"
              crossOrigin="anonymous"
            ></link>
            <link
              rel="preload"
              href={"/fonts/IBMPlexSans-Regular.woff2"}
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            ></link>
            <link
              rel="preload"
              href={"/fonts/IBMPlexSans-Regular.woff"}
              as="font"
              type="font/woff"
              crossOrigin="anonymous"
            ></link>
            <link
              rel="preload"
              href={"/fonts/IBMPlexSans-Medium.woff2"}
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            ></link>
            <link
              rel="preload"
              href={"/fonts/IBMPlexSans-Medium.woff"}
              as="font"
              type="font/woff"
              crossOrigin="anonymous"
            ></link>
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  website: PropTypes.bool,
  blog: PropTypes.bool,
  homepage: PropTypes.bool,
  created: PropTypes.string,
  updated: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  website: false,
  blog: false,
  homepage: false,
  created: null,
  updated: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
        dateCreated
        dateUpdated
      }
    }
  }
`
