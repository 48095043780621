import React, { Component } from "react"
import styled from "@emotion/styled"
import { watchViewport, unwatchViewport } from "tornis"

const Pointer = styled.div`
  position: fixed;
  height: 0.66rem;
  width: 0.66rem;
  background-color: ${props => props.theme.primaryBlue};
  border-radius: 2rem;
  pointer-events: none;
  transition: all 0.19s ease-out;
  top: 0;
  left: 0;
  z-index: 11;
  mix-blend-mode: multiply;
  will-change: transform;
  backface-visibility: hidden;
  display: flex;

  @media (max-width: 46rem) {
    display: none;
  }
`

class Cursor extends Component {
  state = {
    cursor: {
      x: 0,
      y: 0,
    },
  }

  componentWillMount() {
    watchViewport(this.tornis)
  }

  componentWillUnmount() {
    unwatchViewport(this.tornis)
  }

  tornis = ({ mouse }) => {
    if (mouse.changed) {
      // console.log("Cursor ->", mouse)

      const cursor = { ...this.state.cursor }
      cursor.x = mouse.x
      cursor.y = mouse.y
      this.setState({ cursor })
    }
  }

  render() {
    const { cursor } = this.state

    if (typeof document !== "undefined") {
      var links = document.querySelectorAll(["a", "picture", "img", "button"])
      var circle = document.getElementById("circle")

      Array.from(links).forEach(link => {
        link.addEventListener("mouseover", () => {
          circle.style.backgroundColor = "rgb(243,231,0)"
          circle.style.width = "1.4rem"
          circle.style.height = "1.4rem"
        })

        link.addEventListener("mouseout", () => {
          circle.style.backgroundColor = "rgb(0,3,237)"
          circle.style.width = ".66rem"
          circle.style.height = ".66rem"
        })
      })
    }

    return (
      <Pointer
        id="circle"
        style={{
          transform: `translateX(${cursor.x - 7}px) translateY(${cursor.y -
            7}px)`,
        }}
      />
    )
  }
}

export default Cursor
