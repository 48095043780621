import React from "react"
import { Link } from "gatsby"

import styled from "@emotion/styled"

const OtherLinksContainer = styled.div`
  grid-column-end: span 11;
  text-align: center;

  @media (min-width: 46rem) {
    grid-column-start: 6;
    grid-column-end: span 4;
    text-align: left;
  }

  h5 {
    font-size: 1.3rem;
    font-family: "IBMPlexSans", "sans-serif";
    font-weight: 500;

    @media (max-width: 46rem) {
      margin-bottom: 0.6rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0 0.5rem;
      padding: 0;
      display: inline-block;

      @media (min-width: 46rem) {
        &:first-of-type {
          margin-left: 0;
        }
      }

      a {
        color: ${props => props.theme.textColor};
        border-radius: 2rem;
        border: 1px solid ${props => props.theme.default.black};
        padding: 0.25rem 0.6rem;
        text-decoration: none;

        @media (min-width: 46rem) {
          &:hover {
            border-color: ${props => props.theme.primaryBlue};
            background-color: ${props => props.theme.primaryBlue};
            color: ${props => props.theme.default.white};
          }
        }
      }
    }
  }
`

const OtherStuff = () => {
  return (
    <OtherLinksContainer>
      <h5>Stuff and things...</h5>
      <ul>
        <li>
          <Link to={"/uses"} title="Uses">
            Uses
          </Link>
        </li>
        <li>
          <Link to={"/legal"} title="Legal stuff">
            Legal
          </Link>
        </li>
      </ul>
    </OtherLinksContainer>
  )
}

export default OtherStuff
