import React from "react"
import styled from "@emotion/styled"
import ContactIntro from "../site/contact-intro"
import ContactList from "../site/contact-list"
import Copyright from "../site/copyright"
import Listening from "../site/listening"
import OtherStuff from "../site/other-links"
import ToTop from "../site/to-top"

const FooterWrapper = styled.footer`
  padding-top: 2.5rem;
  background-color: ${props => props.theme.primaryYellow};
  box-shadow: inset 0 0 0 0.5rem ${props => props.theme.default.white};
`

const FooterContainer = styled.section`
  padding: 1rem 2rem;

  h5 {
    font-size: 1rem;
  }
`

const TopFooter = styled.div`
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(11, 1fr);
  gap: 2rem 1rem;
  padding-bottom: 2.5rem;
  padding-top: 1rem;

  @media (min-width: 46rem) {
    gap: 2rem;
    padding-bottom: 3.5rem;
    padding-top: 2rem;
  }
`

const BottomFooter = styled.div`
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(11, 1fr);
  gap: 1rem;

  @media (min-width: 46rem) {
    gap: 2rem;
  }

  * {
    font-size: 0.76rem;
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <TopFooter>
          <ContactIntro />
          <OtherStuff />
          <ContactList />
        </TopFooter>
        <BottomFooter>
          <Listening />
          <Copyright />
          <ToTop />
        </BottomFooter>
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer
