import React from "react"

import styled from "@emotion/styled"

const CopyrightContainer = styled.div`
  grid-column-end: span 11;
  text-align: center;

  @media (min-width: 46rem) {
    grid-column-start: 6;
    grid-column-end: span 4;
    text-align: left;
  }

  p {
    margin: 0;
    padding: 0;
  }
`

const Copyright = () => {
  return (
    <CopyrightContainer>
      <p>David Perkins &copy; 2020</p>
    </CopyrightContainer>
  )
}

export default Copyright
