import React from "react"
import styled from "@emotion/styled"

import Logo from "../site/logo"
import MainNav from "./nav/main"
import MobileNav from "./nav/mobile"

const HeaderContainer = styled.header`
  width: 100%;
  max-width: 88rem;
  margin: 0 auto;
  padding: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;

  @media (min-width: 46rem) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
  }

  a {
    color: ${props => props.theme.textColor};
  }
`

const Header = () => {
  return (
    <HeaderContainer>
      <Logo />
      <MainNav />
      <MobileNav />
    </HeaderContainer>
  )
}

export default Header
