import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

import DarkModeToggle from "../../fragments/dark"

const MainNavStyle = styled.nav`
  display: none;
  margin: 0;
  padding: 0;

  @media (min-width: 46rem) {
    display: inline-flex;
    grid-column-end: span 4;
    grid-column-start: 3;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
  }

  ul {
    text-align: right;
    margin: 0;
    padding: 0;

    @media (min-width: 46rem) {
      grid-column-end: span 10;
    }
  }
`

const ListItem = styled.li`
  display: inline-block;
  margin: 0 0 0 1.8rem;

  a {
    transition: box-shadow 0.22s cubic-bezier(1, 0.435, 0.425, 0.32);
    box-shadow: ${props => props.theme.default.white} 0px 0px 0px inset;
    font-size: 105%;
    padding: 0 0.4rem 0.4rem 0.4rem;
    text-decoration: none;

    &.active,
    &:hover {
      transition: box-shadow 0.22s cubic-bezier(1, 0.435, 0.425, 0.32);
      box-shadow: ${props => props.theme.primaryBlue} 0px -3px 0px inset;
    }
  }
`

const MainNav = () => {
  const data = useStaticQuery(graphql`
    query MainMenuLinks {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
        }
      }
    }
  `)

  const mainmenuLinks = data.site.siteMetadata.menuLinks

  return (
    <>
      <MainNavStyle className="main-navigation">
        <ul>
          {mainmenuLinks.map(link => (
            <ListItem key={link.name}>
              <Link to={link.link} title={link.name} activeClassName="active">
                {link.name}
              </Link>
            </ListItem>
          ))}
        </ul>
        <DarkModeToggle />
      </MainNavStyle>
    </>
  )
}

export default MainNav
