import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import styled from "@emotion/styled"

function Social(props) {
  const data = useStaticQuery(graphql`
    query SocialLinks {
      craft {
        globalSets(handle: "social") {
          ... on Craft_social_GlobalSet {
            socialLinks {
              ... on Craft_socialLinks_network_BlockType {
                networkName
                networkUrl
                svgIcon
              }
            }
          }
        }
      }
    }
  `)

  const links = data.craft.globalSets[0].socialLinks

  const SocialNetworks = styled.ul`
    padding: 0;
    list-style: none;

    &.nav {
      display: inline-flex;
      width: 60%;
      margin: 0 0 0 1rem;
    }

    &.footer {
      width: 60%;
      margin: 1rem auto 0;

      @media (min-width: 46rem) {
        margin: 0;
        width: 80%;
      }
    }

    &.black {
      svg {
        fill: ${props => props.theme.textColor};
      }

      @media (min-width: 46rem) {
        svg {
          &:hover {
            fill: ${props => props.theme.default.white};
          }
        }
      }
    }

    &.white {
      svg {
        fill: ${props => props.theme.default.white};
      }
    }
  `

  const SocialLink = styled.li`
    margin: 0;
    padding: 0 0.35rem;
    display: inline-flex;
    width: 20%;
  `

  const socialClass = props.color + " " + props.location

  return (
    <SocialNetworks className={socialClass}>
      {links.map((network, i) => (
        <SocialLink key={i}>
          <a href={`${network.networkUrl}`} title={`${network.networkName}`}>
            <span dangerouslySetInnerHTML={{ __html: network.svgIcon }}></span>
          </a>
        </SocialLink>
      ))}
    </SocialNetworks>
  )
}

export default Social
