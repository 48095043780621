import React from "react"
import { Global, css } from "@emotion/core"

const GlobalStyles = () => {
  return (
    <>
      <Global
        styles={css`
          html {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            -webkit-text-size-adjust: 100%;
            text-rendering: optimizeLegibility;
            text-shadow: rgba(74, 68, 73, 0.01) 0 0 1px;
            box-sizing: border-box;
          }

          section {
            width: 100%;
            max-width: 88rem;
            margin-left: auto;
            margin-right: auto;
            padding-left: 1.4rem;
            padding-right: 1.4rem;
          }

          /* Picture/Media Styling */
          picture {
            display: block;
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            line-height: 0;

            img {
              width: 100%;
              margin: 0;
            }
          }

          /* Page Titles */
          .pageTitle {
            font-size: 2.25rem;

            @media (min-width: 46rem) {
              font-size: 3.75rem;
            }
          }

          /* Hamburger Styling */
          .hamburger {
            display: inline-block;
            cursor: pointer;
            transition-property: filter;
            transition-duration: 0.15s;
            transition-timing-function: linear;
            background-color: transparent;
            border: 0;
            margin: 0;
            padding: 0;
            overflow: visible;
            outline: none;
          }

          .hamburger-box {
            width: 30px;
            height: 20px;
            display: inline-block;
            position: relative;
          }

          .hamburger-inner {
            display: block;
            top: 50%;
            margin-top: -2px;
          }

          .hamburger-inner,
          .hamburger-inner::before,
          .hamburger-inner::after {
            content: "";
            display: block;
            width: 30px;
            height: 4px;
            border-radius: 0px;
            position: absolute;
            transition: background-color 0.4s linear, transform 0.15s 0.15s;
          }

          /* Inactive Hamburger */
          .hamburger-inner,
          .hamburger-inner::before,
          .hamburger-inner::after {
            background-color: rgb(30, 32, 35);
          }

          .hamburger-inner::before {
            transition: top 0.1s 0.1s ease,
              transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
            top: -8px;
          }

          .hamburger-inner::after {
            transition: bottom 0.1s 0.1s ease,
              transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
            bottom: -8px;
          }

          /* Active Hamburger */
          .hamburger.is-active .hamburger-inner,
          .hamburger.is-active .hamburger-inner::before,
          .hamburger.is-active .hamburger-inner::after {
            transition: background-color 0.4s linear, transform 0.15s 0.15s;
            background-color: rgb(30, 32, 35);
          }

          .hamburger.is-active .hamburger-inner::before {
            top: 0;
            transform: translate3d(-7px, -6px, 0) rotate(-45deg) scale(0.7, 1);
            transition: top 0.1s ease,
              transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
          }

          .hamburger.is-active .hamburger-inner::after {
            bottom: 0;
            transform: translate3d(-7px, 6px, 0) rotate(45deg) scale(0.7, 1);
            transition: bottom 0.1s ease,
              transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
          }

          @keyframes move {
            from {
              background-position: 2px bottom;
            }
            to {
              background-position: 500px bottom;
            }
          }

          @keyframes wave-animation {
            0% {
              transform: rotate(0deg);
            }
            10% {
              transform: rotate(-10deg);
            }
            20% {
              transform: rotate(12deg);
            }
            30% {
              transform: rotate(-10deg);
            }
            40% {
              transform: rotate(9deg);
            }
            50% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(0deg);
            }
          }

          @keyframes rotation {
            from {
              -webkit-transform: rotate(0deg);
            }
            to {
              -webkit-transform: rotate(359deg);
            }
          }

          @keyframes toDark {
            from {
              transform: translateX(0rem);
            }
            to {
              transform: translateX(1rem);
            }
          }

          @keyframes toLight {
            from {
              transform: translateX(1rem);
            }
            to {
              transform: translateX(0rem);
            }
          }

          /* Note Content Links */
          .note-content {
            p,
            li {
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }

          /* Dark Mode */
          .light-mode {
            transition: all 0.33s ease;
            background-color: rgb(255, 255, 255);
          }

          .dark-mode {
            transition: all 0.33s ease;
            background-color: rgb(21, 21, 21);

            header {
              svg {
                * {
                  fill: rgb(255, 255, 255);
                }
              }

              label {
                border-color: rgb(255, 255, 255);

                div {
                  background-color: rgb(243, 231, 0);
                }
              }
            }

            * {
              color: rgb(255, 255, 255) !important;

              a {
                color: rgb(255, 255, 255) !important;
              }

              p {
                color: rgb(255, 255, 255) !important;

                a {
                  color: rgb(243, 231, 0) !important;

                  &:hover {
                    color: rgb(255, 255, 255) !important;
                  }
                }
              }
            }

            form {
              input,
              textarea {
                border-color: rgba(255, 255, 255, 0.1) !important;

                &:active,
                &:focus {
                  border-color: rgba(255, 255, 255, 0) !important;
                }
              }

              button {
                &:hover {
                  color: rgb(25, 25, 25) !important;
                }
              }
            }

            .homeExp {
              background-color: rgb(25, 25, 25);

              h3 {
                &:before {
                  background-color: rgb(0, 3, 237);
                }
              }

              h4 {
                &:after {
                  color: rgb(243, 231, 0);
                }
              }
            }

            .project-entry {
              div {
                &:before {
                  box-shadow: inset 0px 0px 0px 0rem rgba(21, 21, 21, 1);
                }
              }

              &:hover {
                div {
                  &:before {
                    box-shadow: inset 0px 0px 0px 0.5rem rgba(21, 21, 21, 1);
                  }
                }
              }
            }

            .hamburger-inner {
              background-color: rgb(255, 255, 255) !important;
              &:before,
              &:after {
                background-color: rgb(255, 255, 255) !important;
              }
            }

            .mobmenu {
              background-color: rgb(30, 32, 35);

              &.active {
                ul li a {
                  color: rgb(255, 255, 255) !important;
                }

                svg {
                  fill: rgb(255, 255, 255);
                }
              }
            }

            div {
              box-shadow: none !important;
            }

            .cta {
              h4 span {
                color: rgb(243, 231, 0);
              }
            }

            #circle {
              mix-blend-mode: difference;
            }

            h4 {
              color: rgb(255, 255, 255) !important;
            }

            footer {
              background-color: rgb(10, 10, 10);
              box-shadow: inset 0 0 0 0.5rem rgb(10, 10, 10);

              a {
                color: rgb(255, 255, 255) !important;
              }

              svg {
                fill: rgb(255, 255, 255) !important;
              }
            }
          }
        `}
      />
    </>
  )
}

export default GlobalStyles
